import { env } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  environment: env.PUBLIC_ENVIRONMENT,
  dsn: 'https://6d47efa36388317ecce9693ad155847d@o160250.ingest.us.sentry.io/4508008423882752',
  beforeSend(event) {
    // Returning null discards the event
    if (!window.Sq?.OneTrust?.analyticsTrackingEnabled) {
      return null;
    }

    return event;
  },
});

window.SentryReady = true;
// Flush queued errors after initialization
window.SentryErrorQueue.forEach((err) => {
  Sentry.captureException(err);
});
// Clear the queue after flushing
window.SentryErrorQueue = [];

export const handleError = Sentry.handleErrorWithSentry();
